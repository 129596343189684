<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-card>
    <v-card-title>
      Historia Importu
      <v-spacer></v-spacer>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="imported"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :search="search"
      class="elevation-1"
    >
      <template v-slot:item.reImport="{ item }">
        <v-btn class="warning" v-if="!item.deleted && !item.isRent" @click="reImport(item.id)">
          Importuj Pominięte
        </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn v-if="!item.deleted" class="error" @click="undoImport(item.id)">
          Cofnij Import
        </v-btn>
        <v-btn v-if="item.deleted" disabled class="warning">
          Anulowano Import
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import * as dayjs from "dayjs";
export default {
  name: "ImportHistoryPage",
  data: () => ({
    search: "",
    options: {
      page: 1,
      itemsPerPage: 10,
    },
    totalItems: 0,
    loading: false,
    headers: [
      { text: "Data Importu", value: "date" },
      {
        text: "Nazwa Pliku",
        align: "start",
        value: "fileName",
      },
      { text: "", value: "actions", sortable: false },
      { text: "", value: "reImport", sortable: false },
    ],
    imported: [],
  }),
  watch: {
    options: {
      async handler() {
        await this.initalize();
      },
      deep: true,
    },
    async search() {
      this.options.page = 1;
      await this.initalize();
    },
  },
  methods: {
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },
    async initalize() {
      this.loading = true;
      if (this.search.trim().length > 0) {
        this.options.page = 1;
      }
      try {
        const imported = await axios({
          method: "get",
          url:
            process.env.VUE_APP_ROOT_API +
            `import?take=${this.options.itemsPerPage}&skip=${this.options.page}&search=${this.search}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.imported = imported.data.data.result.map((imp) => {
          return {
            date: dayjs(imp.createdAt).format("YYYY-MM-DD HH:mm"),
            id: imp.id,
            fileName: imp.description || imp.file[0]?.orginalName,
            deleted: imp.deleted,
            isRent: imp.description,
          };
        });
        this.totalItems = imported.data.data.count;
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      this.loading = false;
    },
    async reImport(id) {
      await this.toggleLoading(true);
      try {
        Swal.fire({
          title: "Napewno?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await axios({
              method: "post",
              url: process.env.VUE_APP_ROOT_API + "import/re-import/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            await this.initalize();
          }
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      await this.toggleLoading(false);
    },
    async undoImport(id) {
      await this.toggleLoading(true);
      try {
        Swal.fire({
          title: "Cofnąć import?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await axios({
              method: "post",
              url: process.env.VUE_APP_ROOT_API + "import/undo/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            await this.initalize();
          }
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      await this.toggleLoading(false);
    },
  },
};
</script>
