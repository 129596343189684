<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-app>
    <v-container>
      <!-- Trigger Button for Dialog -->
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headersForTable"
            :items="mathEqs"
            class="elevation-1"
            :loading="loading"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Wzory</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-tooltip text="Na ten moment moze istniec tylko jedna firma">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" class="primary" @click="openModal()"
                      >Dodaj</v-btn
                    >
                  </template>
                </v-tooltip>
              </v-toolbar>
            </template>

            <template v-slot:item.delete="{ item }">
              <v-btn class="error" @click="deleteMath(item.id)"
                ><v-icon small class="pt-1">mdi-delete</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- Modal -->
      <v-dialog v-model="modal">
        <v-card>
          <v-card-title class="headline"
            >Upload File and Build Equation</v-card-title
          >

          <v-card-text>
            <v-form ref="form">
              <!-- Step 1: File Upload -->
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="provider"
                    :items="providers"
                    item-text="name"
                    item-value="id"
                    label="Dotyczy"
                    :rules="[rules.required]"
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    label="Upload File"
                    accept=".csv, .xlsx"
                    v-model="file"
                    @change="handleFileUpload"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Nazwa"
                    v-model="name"
                    hint="Nazwa"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Math Equation"
                    v-model="customEquation"
                    hint="Use headers and custom numbers as variables"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Cash Field"
                    v-model="cashField"
                    hint="Cash Field"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Id Field"
                    v-model="idField"
                    hint="Id Field"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <!-- Cooperation Type -->
                <v-col cols="12" md="6">
                  <v-select
                    v-model="cooperationType"
                    :items="cooperationTypes"
                    label="Applies to Cooperation Type"
                    :rules="[rules.required]"
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <div>
                    <v-chip
                      v-for="header in headers"
                      :key="header"
                      class="ma-1"
                      outlined
                      color="primary"
                      text-color="black"
                      @click="insertHeaderToEquation(header)"
                    >
                      {{ header }}
                    </v-chip>
                  </div>
                </v-col>
              </v-row>

              <!-- Step 3: Dynamic Custom Numbers -->
              <v-row>
                <v-col cols="12">
                  <h3>Custom Numbers</h3>
                </v-col>
                <v-col
                  v-for="(customNumber, index) in customNumbers"
                  :key="index"
                  cols="12"
                  md="6"
                  lg="4"
                  class="d-flex align-center"
                >
                  <v-text-field
                    v-model="customNumber.label"
                    label="Variable Name"
                    placeholder="e.g., customNum1"
                    outlined
                    dense
                    @input="validateVariableName(index)"
                  ></v-text-field>
                  <v-text-field
                    v-model="customNumber.value"
                    label="Value"
                    placeholder="Enter value"
                    type="number"
                    outlined
                    dense
                  ></v-text-field>
                  <v-btn icon @click="removeCustomNumber(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    text
                    color="primary"
                    @click="addCustomNumber"
                    small
                    class="mt-2"
                  >
                    Add Custom Number
                  </v-btn>
                </v-col>
              </v-row>

              <!-- Step 4: Preview Table -->
              <v-row class="mt-4">
                <v-col cols="12">
                  <v-data-table
                    :headers="tableHeadersWithResult"
                    :items="tableDataWithResults"
                    dense
                    outlined
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>Preview Table</v-toolbar-title>
                      </v-toolbar>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="modal = false">Cancel</v-btn>
            <v-btn color="primary" @click="submitFile">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import Papa from "papaparse";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
const axios = require("axios");
export default {
  data() {
    return {
      mathEqs: [],
      headersForTable: [
        {
          text: "Typ",
          align: "start",
          sortable: false,
          value: "type",
        },
        {
          text: "Wzór",
          align: "start",
          sortable: false,
          value: "equations",
        },
        {
          text: "Pole z Gotówka",
          align: "start",
          sortable: false,
          value: "cashField",
        },
        {
          text: "Pole z ID",
          align: "start",
          sortable: false,
          value: "idField",
        },
        {
          text: "Sposób rozliczenia",
          align: "start",
          sortable: false,
          value: "cooperationType",
        },
        { text: "", value: "delete", sortable: false },
      ],
      modal: false,
      file: null,
      headers: [],
      tableHeadersWithResult: [],
      tableData: [],
      customEquation: "",
      headerMap: {}, // Map for original headers to normalized headers
      customNumbers: [],
      provider: null,
      cooperationType: null,
        cooperationTypes: [
        "B2B_VAT",
        "B2B_NO_VAT",
        "FIFTY_FIFTY",
        "RENT",
        "CONNECTION",
        "STUDENT",
      ],
      providers: [
        {
          name: "BOLT",
          id: "BOLT",
        },
        {
          name: "UBER",
          id: "UBER",
        },
        {
          name: "FN",
          id: "FN",
        },
      ],
      cashField: null,
      name: null,
      idField: null,
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  computed: {
    tableDataWithResults() {
      return this.tableData.map((row) => {
        try {
          const result = this.evaluateEquation(row);
          return { ...row, result };
        } catch (error) {
          return { ...row, result: "Error" };
        }
      });
    },
  },
  async created() {
    await this.initialize();
  },
  methods: {
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },
    async deleteMath(id) {
      Swal.fire({
        title: "Napewno usunąć?",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tak",
        cancelButtonText: "Anuluj",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + "import/math/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            await this.initialize();
          } catch (e) {
            console.log(e);
            Swal.fire("Błąd", e.response.data.message.toString(), "error");
          }
        }
      });
    },
    async initialize() {
      this.loading = true;
      try {
        const mathEqs = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `import/math`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.mathEqs = mathEqs.data.data.map((user) => {
          return {
            equations: user.equationStr,
            type: user.type,
            id: user.id,
            name: user.name,
            cooperationType: user.cooperationType,
            cashField: user.cashField,
            idField: user.idField,
          };
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      this.loading = false;
    },
    openModal() {
      this.modal = true;
    },
    handleFileUpload() {
      if (!this.file) return;

      const reader = new FileReader();
      const fileExt = this.file.name.split(".").pop();

      if (fileExt === "csv") {
        reader.onload = (e) => {
          const data = Papa.parse(e.target.result, { header: true });
          this.processData(data.data);
        };
        reader.readAsText(this.file);
      } else if (fileExt === "xlsx") {
        reader.onload = (e) => {
          const workbook = XLSX.read(e.target.result, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheetData = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheetName]
          );
          this.processData(sheetData);
        };
        reader.readAsBinaryString(this.file);
      } else {
        alert("Invalid file type");
      }
    },
    normalizeHeader(header) {
      return header
        .trim()
        .replace(/\s+/g, "_")
        .replace(/[^a-zA-Z0-9_]/g, "");
    },
    processData(data) {
      if (!data || data.length === 0) return;
      data = data.filter((it) => Object.keys(it).length > 2);
      this.headerMap = {};
      const normalizedHeaders = Object.keys(data[0]).map((header) => {
        const normalized = this.normalizeHeader(header);
        this.headerMap[header] = normalized;
        return normalized;
      });

      this.headers = normalizedHeaders;
      this.tableHeadersWithResult = [
        ...this.headers.map((header) => ({ text: header, value: header })),
        { text: "Result", value: "result" },
      ];

      this.tableData = data.map((row) =>
        Object.keys(row).reduce((acc, key) => {
          const normalizedKey = this.headerMap[key];
          acc[normalizedKey] = row[key];
          return acc;
        }, {})
      );
    },
    insertHeaderToEquation(header) {
      this.customEquation += header;
    },
    evaluateEquation(row) {
      const equation = this.customEquation;

      const rowVariables = this.headers.reduce((vars, header) => {
        vars[header] = parseFloat(row[header]) || 0;
        return vars;
      }, {});

      const customVars = this.customNumbers.reduce((vars, num) => {
        vars[num.label] = parseFloat(num.value) || 0;
        return vars;
      }, {});

      const allVars = { ...rowVariables, ...customVars };

      const equationFunction = new Function(
        ...Object.keys(allVars),
        `return ${equation};`
      );

      return equationFunction(...Object.values(allVars));
    },
    addCustomNumber() {
      this.customNumbers.push({
        label: `customNum${this.customNumbers.length + 1}`,
        value: 0,
      });
    },
    removeCustomNumber(index) {
      this.customNumbers.splice(index, 1);
    },
    validateVariableName(index) {
      const currentName = this.customNumbers[index].label;
      this.customNumbers[index].label = this.normalizeHeader(currentName);
    },
    async submitFile() {
      console.log("File Submitted", {
        type: this.provider,
        equationStr: this.customEquation,
        customNumbers: JSON.stringify(this.customNumbers),
      });
      try {
        await this.toggleLoading(true);
        await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "import/math",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            type: this.provider,
            equationStr: this.customEquation,
            customNumbers: JSON.stringify(this.customNumbers),
            cashField: this.cashField,
            name: this.name,
            cooperationType: this.cooperationType,
            idField: this.idField,
          },
        });
        this.modal = false;
        await this.toggleLoading(false);
        await this.initialize();
      } catch (e) {
        await this.toggleLoading(false);
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
    },
  },
};
</script>

<style scoped>
.v-chip {
  cursor: pointer;
}
</style>
