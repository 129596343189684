<template>
  <v-container>
    <v-card>
      <v-card-title>
        Import Rozliczeń
        <v-spacer></v-spacer>
      </v-card-title>
      <v-form>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="mathEq"
                :items="mathEqs"
                item-text="name"
                item-value="id"
                label="Wzór"
                required
                multiple
              />
            </v-col>
            <v-col cols="12">
              <v-file-input
                v-model="file"
                label="Plik z rozliczeniem"
                 accept=".csv, .xlsx"
              ></v-file-input>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="date"
                label="Data"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn class="primary" style="width: 100%" @click="importFiles()"
                >Importuj</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
        <v-card>
      <v-card-title>
        Import Wynajmu
        <v-spacer></v-spacer>
      </v-card-title>
      <v-container>
        <v-form>
                      <v-col cols="12">
              <v-text-field
                v-model="dateRent"
                label="Data"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn class="primary" style="width: 100%" @click="importRent()"
                >Importuj</v-btn
              >
            </v-col>
        </v-form>
      </v-container>
      </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "UploaderPage",
  data: () => ({
    file: null,
    date: null,
    dateRent: null,
    mathEqs: [],
    mathEq: null,
    rules: {
      required: (value) => !!value || "Required.",
    },
  }),
  async created() {
    await this.getMathEquations();
  },
  methods: {
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },
    async getMathEquations() {
      await this.toggleLoading(true);
      try {
        const mathEqs = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `import/math`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.mathEqs = mathEqs.data.data.map((it) => {
          return {
            id: it.id,
            name: it.name,
          };
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      await this.toggleLoading(false);
    },
  async importRent() {
      if (!this.dateRent) {
        await Swal.fire("Błąd", "Brak Wybranej Daty!", "error");
        return false;
      }
      Swal.fire(
        "Uwaga",
        "Rozpoczęto Import, nie odświeżaj strony!",
        "warning"
      ).then(async () => {
        await this.toggleLoading(true);
        try {
          await axios({
            method: "post",
            url: process.env.VUE_APP_ROOT_API + "import/rent",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data: {
              date: this.dateRent
            },
          });
          this.dateRent = null;
          Swal.fire("Sukces", "Zakończono Import", "success");
        } catch (e) {
          console.log(e);
          Swal.fire("Błąd", e.response.data.message.toString(), "error");
        }
        await this.toggleLoading(false);
      });
    },
    async importFiles() {
      if (!this.date) {
        await Swal.fire("Błąd", "Brak Wybranej Daty!", "error");
        return false;
      }
      Swal.fire(
        "Uwaga",
        "Rozpoczęto Import, nie odświeżaj strony!",
        "warning"
      ).then(async () => {
        await this.toggleLoading(true);
        try {
          const formData = new FormData();
          try {
            formData.append("file", this.file, this.file.name);
          } catch (e) {
            console.log("Missing File");
          }
          formData.append("date", this.date);
          formData.append("equationId", this.mathEq);
          await axios({
            method: "post",
            url: process.env.VUE_APP_ROOT_API + "import",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "multipart/form-data",
            },
            data: formData,
          });
          this.file = null;
          this.date = null;
          this.mathEq = null;
          Swal.fire("Sukces", "Zakończono Import", "success");
        } catch (e) {
          console.log(e);
          Swal.fire("Błąd", e.response.data.message.toString(), "error");
        }
        await this.toggleLoading(false);
      });
    },
  },
};
</script>
