<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-container>
    <!-- Trigger Button for Dialog -->
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="documentTemplates"
          class="elevation-1"
          :loading="loading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Szablony Dokumentów</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn class="primary" @click="dialog = true">Dodaj</v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.downloadTemplate="{ item }">
            <v-btn class="warning" @click="downloadTemplate(item.id)"
              ><v-icon small class="pt-1">mdi-file</v-icon></v-btn
            >
          </template>
          <template v-slot:item.deleteDocumentTemplate="{ item }">
            <v-btn class="error" @click="deleteDocumentTemplate(item.id)"
              ><v-icon small class="pt-1">mdi-delete</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Dialog for Uploading Document -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Upload Template</span>
        </v-card-title>
        <v-card-text>
          <!-- Form for Uploading Document -->
          <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
            <!-- Select Company -->
            <v-row>
              <!-- Description -->
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="templateName"
                  label="Template Name"
                  outlined
                  required
                  :rules="[validateRequired]"
                />
              </v-col>
              <v-col cols="12" md="12">
                <v-file-input
                  v-model="file"
                  label="Upload .docx file"
                  accept=".docx"
                  @change="processFile"
                ></v-file-input>
              </v-col>
              <v-divider class="my-4"></v-divider>
              <v-col cols="12">
                <div v-if="variables.length">
                  <h3>Extracted Variables:</h3>
                  <ul>
                    <li v-for="(variable, index) in variables" :key="index">
                      {{ variable }}
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <!-- Actions: Submit and Close -->
        <v-card-actions>
          <v-btn color="blue darken-1" @click="dialog = false">Cancel</v-btn>
          <v-btn color="green darken-1" @click="submitForm" :disabled="!valid"
            >Upload</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import mammoth from "mammoth";
import Swal from "sweetalert2";
const axios = require("axios");

export default {
  data() {
    return {
      variables: [],
      file: null,
      templateName: null,
      documentTemplates: [],
      headers: [
        {
          text: "Nazwa Dokumentu",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "", value: "downloadDocumentTemplate", sortable: false },
        { text: "", value: "deleteDocumentTemplate", sortable: false },
      ],
      loading: false,
      dialog: false, // Controls visibility of the dialog
      valid: false, // Form validity
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => /.+@.+\..+/.test(value) || "Invalid email.",
        password: (value) =>
          value.length >= 6 || "Password must be at least 6 characters.",
      },
    };
  },
  methods: {
    async processFile() {
      if (!this.file) return;

      const reader = new FileReader();

      reader.onload = async (event) => {
        try {
          const arrayBuffer = event.target.result;
          const result = await mammoth.extractRawText({ arrayBuffer });
          const extractedText = result.value;

          // Extract variables between {{ }}
          this.variables = [...extractedText.matchAll(/{{(.*?)}}/g)].map(
            (match) => match[1].trim()
          );
        } catch (error) {
          Swal.fire("Błąd", "Błąd przy procesowaniu pliku!", "error");
        }
      };

      reader.readAsArrayBuffer(this.file);
    },
    validateRequired(value) {
      return !!value || "This field is required";
    },
    async downloadTemplate(id) {
      const file = await axios({
        method: "post",
        url: process.env.VUE_APP_ROOT_API + "aws/download-file/" + id,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        responseType: "blob",
      });
      console.log(file);
      let blob = new Blob([file.data], { type: file.data.type }),
        url = window.URL.createObjectURL(blob);
      window.open(url);
    },
    async deleteDocumentTemplate(id) {
      Swal.fire({
        title: "Napewno usunąć?",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tak",
        cancelButtonText: "Anuluj",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + "document/template/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            await this.initialize();
          } catch (e) {
            console.log(e);
            Swal.fire("Błąd", e.response.data.message.toString(), "error");
          }
        }
      });
    },
    // Handle form submission (upload document)
    async submitForm() {
      // Perform file validation and API call to upload the document
      if (!this.valid) return;

      const formData = new FormData();
      try {
        formData.append("file", this.file, this.file.name);
      } catch (e) {
        console.log("Missing Image");
      }
      formData.append("name", this.templateName);
      formData.append("metadata", this.variables);
      try {
        await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "documents/template",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
    },
  },
};
</script>

<style scoped>
/* Add any custom styles if needed */
</style>
