<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="bonuses"
          class="elevation-1"
          :loading="loading"
          :search="search"
          :options.sync="options"
          :server-items-length="totalItems"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Bonusy</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn
                class="primary"
                @click="((dialog = true), (currentBonusId = null))"
                >Dodaj</v-btn
              >
            </v-toolbar>
            <div>
              <v-text-field
                v-model="search"
                label="Szukaj"
                class="mx-4"
              ></v-text-field>
            </div>
          </template>
          <template v-slot:item.details="{ item }">
            <v-btn class="warning" @click="infoBonus(item.id)"
              ><v-icon small class="pt-1">mdi-pencil</v-icon></v-btn
            >
          </template>
          <template v-slot:item.deleteBonus="{ item }">
            <v-btn class="error" @click="deleteBonus(item.id)"
              ><v-icon small class="pt-1">mdi-delete</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Add Bonus</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" @submit.prevent="submitBonus">
            <v-row>
              <!-- Date -->
              <v-col cols="12" md="6">
                <v-menu
                  ref="dateMenu"
                  v-model="menuDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="bonus.date"
                      label="Date"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[validateRequired]"
                    />
                  </template>
                  <v-date-picker
                    v-model="bonus.date"
                    no-title
                    scrollable
                    @input="menuDate = false"
                  />
                </v-menu>
              </v-col>

              <!-- Driver -->
              <v-col cols="12" md="6">
                <v-select
                  v-model="bonus.driverId"
                  :items="drivers"
                  label="Driver"
                  outlined
                  required
                  :rules="[validateRequired]"
                  item-text="name"
                  item-value="id"
                />
              </v-col>

              <!-- Status -->
              <v-col cols="12" md="6">
                <v-select
                  v-model="bonus.status"
                  :items="statusOptions"
                  label="Status"
                  outlined
                  required
                  :rules="[validateRequired]"
                />
              </v-col>

              <!-- Status -->
              <v-col cols="12" md="6">
                <v-select
                  v-model="bonus.type"
                  :items="bonusType"
                  label="Type"
                  outlined
                  required
                  :rules="[validateRequired]"
                />
              </v-col>
              <!-- Return Cost -->
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="bonus.name"
                  label="Description"
                  outlined
                  required
                  type="text"
                  :rules="[validateRequired]"
                />
              </v-col>
              <!-- Return Cost -->
              <v-col cols="12" md="12">
                <v-text-field
                  v-model.number="bonus.price"
                  label="Value"
                  outlined
                  required
                  type="number"
                  :rules="[validateRequired, validatePositiveNumber]"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn text @click="dialog = false">Cancel</v-btn>
          <v-btn color="green" :disabled="!valid" @click="submitBonus"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
const axios = require("axios");
import * as dayjs from "dayjs";
export default {
  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      menuDate: false,
      search: "",
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      totalItems: 0,
      bonus: {
        date: null,
        name: null,
        type: null,
        driverId: null,
        status: null,
        price: null,
      },
      headers: [
        {
          text: "Data",
          align: "start",
          sortable: false,
          value: "date",
        },
        {
          text: "Kwota",
          align: "start",
          sortable: false,
          value: "price",
        },
        {
          text: "Kierowca",
          align: "start",
          sortable: false,
          value: "driver",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status",
        },
        { text: "", value: "details", sortable: false },
        { text: "", value: "deleteBonus", sortable: false },
      ],
      drivers: [],
      statusOptions: [
        { text: "PENDING", value: "PENDING" },
        { text: "PAYOUT_CREATED", value: "PAYOUT_CREATED" },
        { text: "PAID", value: "PAID" },
      ],
      bonusType: [
        { text: "POSITIVE", value: "POSITIVE" },
        { text: "NEGATIVE", value: "NEGATIVE" },
      ],
      bonuses: [],
    };
  },
  async created() {
    await this.getDriversSelect();
    await this.initialize();
  },
  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    search: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    validateRequired(value) {
      return !!value || "This field is required";
    },
    validatePositiveNumber(value) {
      return value >= 0 || "Value must be positive";
    },
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },
    async deleteBonus(id) {
      Swal.fire({
        title: "Napewno usunąć?",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tak",
        cancelButtonText: "Anuluj",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + "bonuses/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            await this.initialize();
          } catch (e) {
            console.log(e);
            Swal.fire("Błąd", e.response.data.message.toString(), "error");
          }
        }
      });
    },
    async infoBonus(id) {
      await this.toggleLoading(true);
      try {
        const bonus = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "bonuses/details/" + id,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.bonus = bonus.data.data;
        this.currentBonusId = bonus.data.data.id;
        this.bonus.driverId = bonus.data.data.driver.id;
        delete bonus.data.data.driver;
        this.bonus.date = dayjs(bonus.data.data.date).format("YYYY-MM-DD");
        this.bonus.price = bonus.data.data.price / 100;
        this.dialog = true;
        await this.toggleLoading(false);
      } catch (e) {
        await this.toggleLoading(false);
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
    },
    async submitBonus() {
      // Process the bonus data here
      console.log("Bonus submitted:", this.bonus);
      try {
        await this.toggleLoading(true);
        await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "bonuses",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            ...this.bonus,
            id: this.currentBonusId || undefined,
          },
        });
        this.closeDialog();
        await this.toggleLoading(false);
        await this.initialize();
      } catch (e) {
        await this.toggleLoading(false);
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
    },
    async initialize() {
      this.loading = true;
      if (this.search.trim().length > 0) {
        this.options.page = 1;
      }
      try {
        const bonuses = await axios({
          method: "get",
          url:
            process.env.VUE_APP_ROOT_API +
            `bonuses/all?take=${this.options.itemsPerPage}&skip=${this.options.page}&search=${this.search}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.bonuses = bonuses.data.data.result.map((bonus) => {
          return {
            price: bonus.price / 100,
            driver: bonus.driver.firstName + " " + bonus.driver.lastName,
            status: bonus.status,
            date: dayjs(bonus.date).format("DD-MM-YYYY"),
            id: bonus.id,
          };
        });
        this.totalItems = bonuses.data.data.count;
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      this.loading = false;
    },
    async getDriversSelect() {
      await this.toggleLoading(true);
      try {
        const drivers = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `drivers?take=100&skip=1`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.drivers = drivers.data.data.result.map((it) => {
          return {
            id: it.id,
            name: `${it.firstName} ${it.lastName}`,
          };
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      await this.toggleLoading(false);
    },
    closeDialog() {
      this.dialog = false;
      this.currentBonusId = undefined;
      this.$refs.form.resetValidation(); // Reset form validation
    },
  },
};
</script>
