<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="invoices"
          class="elevation-1"
          :loading="loading"
          :search="search"
          :options.sync="options"
          :server-items-length="totalItems"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Faktury Kosztowe</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn
                class="primary"
                @click="((dialog = true), (currentInvoiceId = null))"
                >Dodaj</v-btn
              >
            </v-toolbar>
            <div>
              <v-text-field
                v-model="search"
                label="Szukaj"
                class="mx-4"
              ></v-text-field>
            </div>
          </template>
          <template v-slot:item.details="{ item }">
            <v-btn class="warning" @click="infoInvoice(item.id)"
              ><v-icon small class="pt-1">mdi-pencil</v-icon></v-btn
            >
          </template>
          <template v-slot:item.deleteInvoice="{ item }">
            <v-btn class="error" @click="deleteInvoice(item.id)"
              ><v-icon small class="pt-1">mdi-delete</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Upload Invoice</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" @submit.prevent="submitInvoice">
            <v-row>
              <!-- File Upload with Preview -->
              <v-col cols="12">
                <v-file-input
                  v-model="invoice.file"
                  label="Upload Invoice"
                  outlined
                  accept="image/*,.pdf, image/*"
                  @change="previewFile"
                />
                <iframe
                  v-if="invoice.preview"
                  :src="invoice.preview"
                  style="width: 100%; height: 400px"
                  frameborder="0"
                ></iframe>
              </v-col>
              <!-- Date -->
              <v-col cols="12" md="12">
                <v-menu
                  ref="dateMenu"
                  v-model="menuDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="invoice.date"
                      label="Date"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[validateRequired]"
                    />
                  </template>
                  <v-date-picker
                    v-model="invoice.date"
                    no-title
                    scrollable
                    @input="menuDate = false"
                  />
                </v-menu>
              </v-col>
              <!-- Brutto Input -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="invoice.name"
                  label="Description"
                  outlined
                  required
                  type="text"
                  :rules="[validateRequired]"
                />
              </v-col>

              <!-- Brutto Input -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model.number="invoice.brutto"
                  label="Brutto (Gross Amount)"
                  outlined
                  required
                  type="number"
                  :rules="[validateRequired]"
                  @input="calculateNetto"
                />
              </v-col>

              <!-- Tax Select -->
              <v-col cols="12" md="6">
                <v-select
                  v-model.number="invoice.tax"
                  :items="taxRates"
                  label="Tax (%)"
                  outlined
                  required
                  :rules="[validateRequired]"
                  @change="calculateNetto"
                />
              </v-col>

              <!-- Netto (Calculated) -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="invoice.netto"
                  label="Netto (Net Amount)"
                  outlined
                  readonly
                />
              </v-col>


              <v-col cols="12" md="12">
                <v-select
                  v-model="invoice.type"
                  :items="invoiceType"
                  label="Status"
                  outlined
                  required
                  :rules="[validateRequired]"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn text @click="dialog = false">Cancel</v-btn>
          <v-btn color="green" :disabled="!valid" @click="submitInvoice"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
const axios = require("axios");
import * as dayjs from "dayjs";
export default {
  data() {
    return {
      currentInvoiceId: null,
      loading: false,
      totalItems: 0,
      search: "",
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      invoices: [],
      menuDate: false,
      headers: [
        {
          text: "Data",
          align: "start",
          sortable: false,
          value: "date",
        },
        {
          text: "Brutto",
          align: "start",
          sortable: false,
          value: "brutto",
        },
        {
          text: "Netto",
          align: "start",
          sortable: false,
          value: "netto",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status",
        },
        { text: "", value: "details", sortable: false },
        { text: "", value: "deleteInvoice", sortable: false },
      ],
      dialog: false,
      valid: false,
      invoice: {
        name: null,
        type: null,
        date: null,
        file: null,
        preview: null,
        brutto: null,
        netto: null,
        tax: null,
      },
      taxRates: [
        { text: "0%", value: 0 },
        { text: "5%", value: 5 },
        { text: "8%", value: 8 },
        { text: "22%", value: 22 },
        { text: "23%", value: 23 },
      ],
      invoiceType: [
        { text: "PENDING", value: "PENDING" },
        { text: "PAYOUT_CREATED", value: "PAYOUT_CREATED" },
        { text: "PAID", value: "PAID" },
      ],
    };
  },
  async created() {
    await this.initialize();
  },
  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    search: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },
    validateRequired(value) {
      return !!value || "This field is required";
    },
    previewFile(file) {
      console.log(file);
      if (file && file instanceof File) {
        const reader = new FileReader();
        reader.onload = (e) => {
          console.log(e.target.result);
          this.invoice.preview = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    calculateNetto() {
      if (this.invoice.brutto != null && this.invoice.tax != null) {
        const taxMultiplier = 1 + this.invoice.tax / 100;
        this.invoice.netto = (this.invoice.brutto / taxMultiplier).toFixed(2);
      }
    },
    async submitInvoice() {
      // Process the invoice data here
      console.log("Invoice submitted:", this.invoice);
      await this.toggleLoading(true);
      if (!this.valid) return;
      // Combine predefined metadata with additional metadata
      const formData = new FormData();
      try {
        formData.append("file", this.invoice.file, this.invoice.file.name);
      } catch (e) {
        console.log("Missing Image");
      }
      formData.append("id", this.currentInvoiceId || undefined);
      formData.append("name", this.invoice.name);
      formData.append("brutto", parseFloat(this.invoice.brutto));
      formData.append("taxRate", parseFloat(this.invoice.tax));
      formData.append("netto", parseFloat(this.invoice.netto));
      formData.append("date", this.invoice.date);
      formData.append("status", this.invoice.type);
      try {
        await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "cost-invoices",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        });
        await this.initialize();
        this.closeDialog();
      } catch (e) {
        await this.toggleLoading(false);
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      await this.toggleLoading(false);
    },
    async initialize() {
      this.loading = true;
      if (this.search.trim().length > 0) {
        this.options.page = 1;
      }
      try {
        const invoices = await axios({
          method: "get",
          url:
            process.env.VUE_APP_ROOT_API +
            `cost-invoices/all?take=${this.options.itemsPerPage}&skip=${this.options.page}&search=${this.search}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.invoices = invoices.data.data.result.map((bonus) => {
          return {
            brutto: bonus.brutto / 100,
            tax: bonus.tax,
            netto: bonus.netto / 100,
            status: bonus.status,
            date: dayjs(bonus.date).format("DD-MM-YYYY"),
            id: bonus.id,
          };
        });
        this.totalItems = invoices.data.data.count;
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      this.loading = false;
    },
    async deleteInvoice(id) {
      Swal.fire({
        title: "Napewno usunąć?",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tak",
        cancelButtonText: "Anuluj",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + "cost-invoices/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            await this.initialize();
          } catch (e) {
            console.log(e);
            Swal.fire("Błąd", e.response.data.message.toString(), "error");
          }
        }
      });
    },
    async infoInvoice(id) {
      await this.toggleLoading(true);
      try {
        const invoices = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "cost-invoices/details/" + id,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.currentInvoiceId = invoices.data.data.id;
        this.invoice.date = dayjs(invoices.data.data.date).format("YYYY-MM-DD");
        this.invoice.name = invoices.data.data.name;
        this.invoice.brutto = invoices.data.data.brutto / 100;
        this.invoice.netto = invoices.data.data.netto / 100;
        this.invoice.tax = invoices.data.data.taxRate;
        this.invoice.type = invoices.data.data.status;
        this.dialog = true;
        await this.toggleLoading(false);
      } catch (e) {
        await this.toggleLoading(false);
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
    },
    closeDialog() {
      this.dialog = false;
      this.currentInvoiceId = undefined;
      this.$refs.form.resetValidation(); // Reset form validation
    },
  },
};
</script>
