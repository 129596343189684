<!-- eslint-disable vue/valid-v-slot -->

<template>
  <v-app>
    <v-container>
      <!-- Filter Section -->
      <v-card outlined class="mb-4">
        <v-card-title>Filters</v-card-title>
        <v-card-text>
          <v-row>
            <!-- Department Filter -->
            <v-col cols="12" md="3">
              <v-select
                v-model="filters.subCompany"
                :items="subCompanies"
                label="Oddział"
                outlined
                dense
                @change="initialize"
                multiple
                item-text="name"
                item-value="id"
              ></v-select>
            </v-col>

            <!-- Start Date Filter -->
            <v-col cols="12" md="3">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.startDate"
                    label="Start Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    @change="initialize()"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.startDate"
                  @input="
                    startDateMenu = false;
                    initialize();
                  "
                ></v-date-picker>
              </v-menu>
            </v-col>

            <!-- End Date Filter -->
            <v-col cols="12" md="3">
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.endDate"
                    label="End Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    @change="initialize"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.endDate"
                  @input="
                    endDateMenu = false;
                    initialize();
                  "
                ></v-date-picker>
              </v-menu>
            </v-col>

            <!-- Settlement Type Filter -->
            <v-col cols="12" md="3">
              <v-select
                v-model="filters.settlementType"
                :items="settlementTypes"
                label="Settlement Type"
                outlined
                dense
                @change="initialize"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Editable Data Table -->
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :search="search"
        :options.sync="options"
        :server-items-length="totalItems"
        class="elevation-1"
        dense
        outlined
        editable
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Editable Data Table</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="createSettlement()" small
              >Rozlicz!</v-btn
            >
          </v-toolbar>
        </template>
      </v-data-table>
    </v-container>
  </v-app>
</template>

<script>
import * as dayjs from "dayjs";
import Swal from "sweetalert2";
const axios = require("axios");
export default {
  data() {
    return {
      loading: false,
      search: "",
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      totalItems: 0,
      // Filters
      filters: {
        subCompany: null,
        startDate: dayjs().startOf("week"),
        endDate: dayjs().endOf("week"),
        settlementType: "WEEKLY",
      },
      startDateMenu: false,
      endDateMenu: false,

      // Data
      items: [],
      nextId: 3,

      // Options
      subCompanies: [],
      settlementTypes: ["DAILY", "WEEKLY", "EVERY_2_WEEKS", "MONTH"],

      // Table Headers
      headers: [
        { text: "driver", value: "driver" },
        { text: "Settlement Type", value: "settlementType" },
        { text: "Bolt", value: "totalBolt" },
        { text: "FreeNow", value: "totalFn" },
        { text: "Uber", value: "totalUber" },
        { text: "Wynajem", value: "totalRent" },
        { text: "Bonus", value: "totalBonus" },
        { text: "Zwrot VAT", value: "totalVat" },
        { text: "Gotówka", value: "totalCash" },
        { text: "Koszt Rozliczenia", value: "settlementCost" },
        { text: "Koszt Administracyjny", value: "administrationTax" },
        { text: "Poprzedni Tydzień", value: "totalPrevious" },
        { text: "Ten Tydzień", value: "totalWeek" },
        { text: "Do wypłaty", value: "total" },
      ],
    };
  },
  async created() {
    this.setDefaultDates();
    await this.getSubcompanySelect();
    await this.initialize();
  },
  methods: {
    async createSettlement() {
      Swal.fire("Uwaga", "Rozpoczęto Generowanie Wypłat!", "warning").then(
        async () => {
          await this.toggleLoading(true);
          try {
            await axios({
              method: "post",
              url: process.env.VUE_APP_ROOT_API + "settlements/create",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              data: {
                startDate: this.filters.startDate,
                endDate: this.filters.endDate,
                subCompanyIds: this.filters.subCompany,
                settlementType: this.filters.settlementType,
                search: this.search,
              },
            });
            Swal.fire("Sukces", "Zakończono", "success");
            await this.initialize();
          } catch (e) {
            console.log(e);
            Swal.fire("Błąd", e.response.data.message.toString(), "error");
          }
          await this.toggleLoading(false);
        }
      );
    },
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },
    async getSubcompanySelect() {
      await this.toggleLoading(true);
      try {
        const subCompanies = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `sub-company`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.subCompanies = subCompanies.data.data.map((it) => {
          return {
            id: it.id,
            name: it.name,
          };
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      await this.toggleLoading(false);
    },
    setDefaultDates() {
      // Get current date
      const currentDate = dayjs();

      // Get start of the week (Sunday or Monday depending on locale)
      const startOfWeek = currentDate.startOf("week"); // Defaults to Sunday
      this.filters.startDate = startOfWeek.format("YYYY-MM-DD");

      // Get end of the week (Saturday or Sunday depending on locale)
      const endOfWeek = currentDate.endOf("week"); // Defaults to Saturday
      this.filters.endDate = endOfWeek.format("YYYY-MM-DD");
    },
    async initialize() {
      this.loading = true;
      if (this.search.trim().length > 0) {
        this.options.page = 1;
      }
      try {
        const response = await axios.get(
          process.env.VUE_APP_ROOT_API +
            `settlements?take=${this.options.itemsPerPage}&skip=${this.options.page}&search=${this.search}`,
          {
            params: {
              subCompany: this.filters.subCompany,
              startDate: this.filters.startDate,
              endDate: this.filters.endDate,
              settlementType: this.filters.settlementType,
            },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.items = response.data.data.result.map((it) => {
          return {
            id: it.id,
            driver: it.firstName + " " + it.lastName,
            settlementType: it.payoutSchedule,
            totalBolt: it.totalBolt / 100,
            totalFn: it.totalFreeNow / 100,
            totalUber: it.totalUber / 100,
            totalRent: it.totalRent / 100,
            totalBonus: it.totalBonuses / 100,
            totalVat: it.totalInvoices / 100,
            totalCash: it.totalCash / 100,
            totalPrevious: it.totalPrevious / 100,
            settlementCost: it.settlementCost / 100,
            administrationTax: it.administrationTax / 100,
            totalWeek: it.totalWeek / 100,
            total: it.total / 100,
          };
        });
        this.totalItems = response.data.data.count;
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.v-toolbar-title {
  font-weight: bold;
}
</style>
