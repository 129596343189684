<template>
  <v-app id="inspire">
    <v-app-bar app v-if="user == 'true'" color="blue darken-3">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title></v-toolbar-title>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        style="z-index: 9999"
        color="deep-purple accent-4"
      ></v-progress-linear>
    </v-app-bar>
    <v-overlay :value="overlay" style="z-index: 9999"></v-overlay>

    <v-navigation-drawer v-model="drawer" fixed temporary v-if="user == 'true'">
      <v-sheet color="blue lighten-2" class="pa-4">
        <v-avatar class="mb-4" color="black darken-1" size="64" >
                <!-- <img src="../public/img/icons/android-chrome-72x72.png" alt="BTP"> -->
        </v-avatar>
      </v-sheet>
      <v-list>
        <v-list-item
          v-for="[icon, text, link] in links"
          :key="icon"
          :to="link"
          active-class="blue darken-3--text "
        >
          <v-list-item-icon>
            <v-icon v-if="icon">{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn color="blue darken-3" block @click="logout()"> Wyloguj </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
    <v-footer padless color="blue darken-3">
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} — <strong>BTP</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>
<script>
const axios = require("axios");
export default {
  name: "App",
  data: () => ({
    user: false,
    drawer: null,
    loading: false,
    overlay: false,
    links: [],
  }),
  async mounted() {
    this.user = localStorage.getItem("user");
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    window.addEventListener("toggleLoading", (event) => {
      this.loading = event.detail.loading == "true" ? true : false;
      this.overlay = event.detail.overlay == "true" ? true : false;
    });
    this.links = [
      ["mdi-domain", "Firma", "company"],
      ["mdi-domain-plus", "Oddziały", "subcompanies"],
      // ["mdi-warehouse", "Oddziały", "departaments"],
      ["mdi-bank", "Konta Bankowe", "bank-accounts"],
      ["mdi-account", "Administratorzy", "admins"],
      ["mdi-car-multiple", "Flota", "fleet"],
      ["mdi-gas-station", "Karty Paliwowe", "fuel-cards"],
      ["mdi-taxi", "Kierowcy", "drivers"],
      ["mdi-file", "Szablony Dokumentów", "document-templates"],
      ["mdi-plus-box-multiple", "Bonusy", "bonuses"],
      ["mdi-car-key", "Wynajem", "car-rent"],
      ["mdi-invoice-text-edit", "FV od Kierowców", "invoices"],
      ["mdi-invoice-text-check", "FV Kosztowe", "cost-invoices"],
      ["mdi-cash-fast", "Przychody", "incomes"],
      ["mdi-upload-box", "Wzory", "uploader-templates"],
      ["mdi-import", "Importer", "uploader"],
      ["mdi-history", "Historia Importu", "import-history"],
      ["mdi-cash-sync", "Rozliczenia", "settlements"],
    ];
  },
  methods: {
    logout() {
      localStorage.setItem("token", null);
      localStorage.setItem("user", false);
      window.location.href = "/login";
    },
  },
};
</script>
